import React from 'react'
import { Suspense } from 'react';
import Loading3D from './Loading3D';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

function onLoad(spline) {
}

export default function SplineLayer() {
  return (
    <div className='m-2' id='spline-layer'>
      <Suspense fallback={<Loading3D />}>
        {
          <Spline scene="https://prod.spline.design/VeT5T2bqxB5hnF4i/scene.splinecode" onLoad={onLoad}/>
        }
      </Suspense>
    </div>
  )
}
