// App.js
import { Routes, Route } from 'react-router-dom';
import ImageCarousel from './components/ImageCarousel';
import FavPicture from './components/FavPicture';
import Home from './Home';

const App = () => {
 return (
    <>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<ImageCarousel />} />
          <Route path="/favpicture" element={<FavPicture />} />
       </Routes>
    </>
 );
};

export default App;