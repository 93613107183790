import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { useRef } from "react";
import gsap from "gsap"; // <-- import GSAP
import { useScroll } from '@react-spring/web'
import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
//import Audic from 'audic';

import Login from './components/Login';
import Interactive from './components/Interactive';
import SplineLayer from './components/SplineLayer';
import { SCROLL_THRESHOLD, validCredentials } from './config/config'
import ImageSlideshow from './components/ImageSlideshow';

export default function Home() {
  const parallaxRef = useRef();
  const container = useRef();
  const [playing, setPlaying] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
    
  const handleLogin = (username, password) => {
    if (username === validCredentials.username && password === validCredentials.password) {
      setIsLoggedIn(true);
    } else {
      toast('Invalid credentials!');
    }
  };

  const completed = () => {
      //tl.reverse();
      setPlaying(false);
      console.log("Completed. Playing?", playing);
  }

  const tl = gsap.timeline({ onComplete: completed, paused: true });
  /*const { scrollYProgress } = useScroll({
    container: container,
    onChange: ({ value: { scrollYProgress }}) => {
    	if(playing){
    	  return
    	}
	
    	if(scrollYProgress > SCROLL_THRESHOLD) {
    	  setPlaying(true);
        //document.getElementById("parallaxReference").classList.add(["paused"])
    	  console.log(" scroll playing!",)
    	  tl.play();
    	}
    }
  });*/
  
  const images = [
    "../../public/images/img1.jpg",
    "../../public/images/img2.jpg",
    "../../public/images/img3.jpg",
    "../../public/images/img4.jpg",
  ];

  /*const audic = new Audic("../../public/images/delilah.mp3");
  audic.addEventListener('ended', () => {
    audic.destroy();
  });*/

  return (
    <div className="Home">
      {isLoggedIn ? (
        <div>
          <Parallax id="parallaxReference" ref={parallaxRef} pages={2} style={{ top: '0', left: '0' }} className="animation gradient h-screen">
            <ParallaxLayer offset={0} speed={0} factor={0.5} sticky={{}}>
			        <SplineLayer></SplineLayer>
            </ParallaxLayer>
            <ParallaxLayer className="w-screen" offset={0.80} factor={1} speed={0.25} sticky={{start: 1}}>
              	<div id="interactive-container" className='h-full border border-stone-500 backdrop-blur-md rounded-lg mx-4 p-2 flex flex-col flex-row text-center items-center'>
              	  <Interactive container={container} tl={tl} playing={playing}></Interactive>
                  <ImageSlideshow images={images}></ImageSlideshow>
                </div>
                <footer className='bg-stone-300 text-black w-full flex flex-col items-center text-center absolute bottom-0 left-50 font-thin text-[14px]'>
            	    with love -Ankit
            	  </footer>
            </ParallaxLayer>
            <ParallaxLayer className="flex flex-col justify-center items-center" offset={0.80} factor={1} speed={0.25} sticky={{start: 1}}>
              {!playing && <button className="bg-white absolute bottom-10 max-w-[50px] py-1 px-2 rounded-lg text-black ease-in-out hover:scale-110 hover:bg-stone-100" onClick={async () => {console.log("Played"); tl.restart(); setPlaying(true);/*await audic.play();*/}}>Play</button>}
            </ParallaxLayer>
            {/*<ParallaxLayer>
              <a href="/gallery">Gallery</a>
            </ParallaxLayer>*/}
          </Parallax>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    <Toaster />
    </div>
  );
}
