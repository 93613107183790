import React, { useState, useEffect } from 'react';

const ImageSlideshow = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  const handleScroll = () => {
    if (hasReachedEnd) return;

    const scrollPosition = window.scrollY;
    const newIndex = Math.floor(scrollPosition / window.innerHeight);

    if (newIndex < images.length) {
      setCurrentImageIndex(newIndex);
    } else {
      setHasReachedEnd(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasReachedEnd]);

  return (
    <div className='hidden'>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          style={{ display: index === currentImageIndex ? 'block' : 'none' }}
        />
      ))}
    </div>
  );
};

export default ImageSlideshow;
