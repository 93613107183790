import React from 'react'
import ImageGallery from "react-image-gallery";
import {img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28} from './ImageImporter'

export default function ImageCarousel() {
    const images = [{original: img1}, {original: img2}, {original: img3}, {original: img4}, {original: img5}, {original: img6},
         {original: img7}, {original: img8}, {original: img9}, {original: img10}, {original: img11}, {original: img12}, 
         {original: img13}, {original: img14}, {original: img15}, {original: img16}, {original: img17},{original: img18}, 
         {original: img19}, {original: img20}, {original: img21}, {original: img22}, {original: img23}, {original: img24}, 
         {original: img25}, {original: img26}, {original: img27}, {original: img28}];  
    return (
        <div className='bg-gradient-to-b from-black via-black to-gray-700 bg-gradient-to-r h-screen flex flex-col justify-center items-center'>
            <a href="/" className='absolute bottom-10'>go back?</a>
            <ImageGallery items={images} />
        </div>
    )
}
