import React from 'react'
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { Expo, Elastic } from 'gsap';
import {img1, img4, img7, img9, img12, img14, img15, img17, img19, img20, img21, img24, img28} from './ImageImporter'

import "./interactive.css";

function Interactive({tl, container, playing}) {
    const hello = useRef();
    const nowplaying = useRef();

    const ideaTextTrans = {
        duration: 0.7,
        opacity: 0,
        y: -20,
        rotationX: 5,
        skewX: "15deg"
    };
    
    const ideaTextTransLeave = {
      duration: 0.7,
      opacity: 0,
      y: 20,
      rotationY: 5,
      skewX: "-15deg"
    };

    const test = false;
    const setReplayable = (replayable) => {
        if(replayable){
            document.getElementById("replay-button").classList.remove(["disabled", "hidden"]);
            return;
        }
        document.getElementById("replay-button").classList.add(["disabled", "hidden"]);
    }

    useGSAP((context, contextSafe) => {
        tl
        .call(() => {console.log("Disabling Button"); setReplayable(false);}, null, "+=2")
        .to(nowplaying.current, {delay: 0, visibility:"visible"})
        //.to(hello.current, {delay:2, scale: "+=1", rotate: 360})
        //.to(hello.current, {delay: 1, scale: "+=1"})
        //.call(() => {console.log("WHATAT!")}, null, ">")
        //.call(() => {console.log("TATATA!")}, null, ">")
        //.to(container.current, { duration: 0.1, visibility: "visible" })
        .from(".one", {duration: 0.7, opacity: 0, y: 10 })
        .from(".two", {duration: 0.4, opacity: 0, y: 10 })
        .to(".one", { duration: 0.7, opacity: 0, y: 10}, "+=2.5")
        .to(".two", { duration: 0.7, opacity: 0, y: 10 }, "-=1")
        .fromTo(".text-1", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-1", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-2", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-2", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-3", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-3", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-4", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-4", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-5", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-5", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-6", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-6", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-7", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-7", { duration: 0.7, opacity: 0, y: 10 })
        .fromTo(".text-8", { opacity: 0 }, { duration: 0.7, opacity: 1, y: 10 })
        .to(".text-8", { duration: 0.7, opacity: 0, y: 10 })
        .from(".three", { duration: 0.7, opacity: 0, y: 10 })
        .from(".text1", { duration: 0.7, opacity: 0, y: 10 })
        .from(".text2", { duration: 0.7, opacity: 0, y: 10 })
        .from(".text3", { duration: 0.7, opacity: 0, y: 10 })
        .from(".text4", { duration: 0.7, opacity: 0, y: 10 })
        .to(".three", { duration: 0.7, opacity: 0, y: 10 },"+=2")
        .from(".four", {duration: 0.7, scale: 0.2, opacity: 0 })
        .from(".fake-btn", { duration: 0.3, scale: 0.2, opacity: 0 })
        .to(".hbd-chatbox", { duration: 0.5, visibility: "visible", stagger: 0.1 })
        .to(".fake-btn", {duration: 0.1, backgroundColor: "rgb(127, 206, 248)"})
        .to(".askin", { duration: 0.5, visibility: "visible", stagger: 0.1 })
        .to(".four", { duration: 0.5, scale: 0.2, opacity: 0, y: -150 }, "+=0.7")
        .from(".idea-1", ideaTextTrans)
        .to(".idea-1", ideaTextTransLeave, "+=1.5")
        .from(".idea-2", ideaTextTrans)
        .to(".idea-2", ideaTextTransLeave, "+=1.5")
        .from(".idea-3", ideaTextTrans)
        .from(".subidea-1", ideaTextTrans)
        .from(".subidea-2", ideaTextTrans)
        .to(".subidea-3", { duration: 0.5, scale: 1.2, x: 10, backgroundColor: "rgb(21, 161, 237)", color: "#fff" })
        .from(".smart", { duration: 0.5, opacity: 0})
        .to(".smart", { duration: 0.5, scale: 1.2, x: 10, backgroundColor: "rgb(21, 161, 237)", color: "#fff" })
        .to(".idea-3", ideaTextTransLeave, "+=1.5")
        .from(".idea-4", ideaTextTrans)
        .from(".subidea-4", ideaTextTrans)
        .from(".subidea-5", ideaTextTrans)
        .to(".subidea-6", { duration: 0.5, scale: 1.2, x: 10, backgroundColor: "rgb(21, 161, 237)", color: "#fff" })
        .to(".subidea-4", {duration: 0.3, opacity: 0})
        .to(".subidea-5", {duration: 0.3, opacity: 0})
        .to(".subidea-6", {duration: 0.3, opacity: 0})
        .from(".stronk", { duration: 0.5, opacity: 0})
        .to(".stronk", { duration: 0.5, opacity: 1, scale: 1.2, x: 10, backgroundColor: "rgb(21, 161, 237)", color: "#fff" })
        .to(".idea-4", ideaTextTransLeave, "+=1.5")
        .from(".idea-45", { duration: 0.7, rotationX: 15, rotationZ: -10, skewY: "-5deg", y: 50, z: 10, opacity: 0 }, "+=0.5")
        .to(".idea-45", { duration: 0.7, scale: 0.2, opacity: 0 }, "+=2")
        .fromTo(".idea-45 .an", { duration: 0, opacity: 0}, {duration: 0.7, opacity: 1})
        .to(".idea-45 .an", {duration: 0.7, opacity: 0})
        .from(".idea-5", { duration: 0.7, rotationX: 15, rotationZ: -10, skewY: "-5deg", y: 50, z: 10, opacity: 0 }, "+=0.5")
        .to(".idea-5 .smiley", { duration: 0.7, rotation: 90, x: 8 }, "+=0.4")
        .to(".idea-5", { duration: 0.7, scale: 0.2, opacity: 0 }, "+=2")
        //.from(".idea-6 span", { duration: 0.8, scale: 3, opacity: 0, rotation: 15, ease: Expo.easeOut, stagger: 0.2 })
        //.to(".idea-6 span", { duration: 0.7, scale: 3, opacity: 0, rotation: -15, ease: "Expo.easeOut", stagger: 0.2, delay: 1})
        .from(".bigTextPart1", { duration: 0.8, scale: 3, opacity: 0, rotation: 15, ease: Expo.easeOut, stagger: 0.2 })
        .from(".bigTextPart2", { duration: 0.8, scale: 3, opacity: 0, rotation: 15, ease: Expo.easeOut, stagger: 0.2 })
        .from(".bigTextPart3", { duration: 0.8, scale: 3, opacity: 0, rotation: 15, ease: Expo.easeOut, stagger: 0.2 })
        .to(".bigTextPart3", { duration: 0.7, scale: 3, opacity: 0, rotation: -15, ease: "Expo.easeOut", stagger: 0.2, delay: 1})
        .to(".bigTextPart2", { duration: 0.4, scale: 3, opacity: 0, rotation: -15, ease: "Expo.easeOut", stagger: 0.2, delay: 1})
        .to(".bigTextPart1", { duration: 0.3, scale: 3, opacity: 0, rotation: -15, ease: "Expo.easeOut", stagger: 0.2, delay: 1})
        //.fromTo(".baloons img", { opacity: 0.9, y: 1400 }, { duration: 2.5, opacity: 1, y: -1000, stagger: 0.2 })
        //.from(".aishb-dp", { duration: 0.5, scale: 3.5, opacity: 0, x: 25, y: -25, rotationZ: -45 }, "-=2")
        //.from(".hat", { duration: 0.5, x: -100, y: 350, rotation: -180, opacity: 0})
        .to(".wish", { duration: 0, visibility: "visible"})
        .from(".wishHBD", { duration: 0.7, opacity: 0, y: -50, rotation: 150, skewX: "30deg", ease: Elastic.easeOut.config(1, 0.5), stagger: 0.1})
        .fromTo(".wishHBD", { scale: 1.4, rotationY: 150 }, { duration: 0.7, scale: 1, rotationY: 0, color: "#ff69b4", ease: "Expo.easeOut", stagger: 0.1, overwrite: "auto" })
        .from(".wishNext", { duration: 0.5, opacity: 0, y: 10, skewX: "-15deg" })
        .to(".eight svg", { duration: 1.5, visibility: "visible", opacity: 0, scale: 60, repeat: 3, repeatDelay: 0.4, stagger: 0.3 })
        .to(".six", { duration: 0.5, opacity: 0, y: 30, zIndex: "-1"})
        .from(".linx", { duration: 0.5, opacity: 0, y: 30, zIndex: "-1"})
        .to(".linx", { duration: 0.5, opacity: 1, y: 30, zIndex: "-1"})

        
        .call(() => {console.log("TEEBEE");}, null, "+=2")
        .call(() => {console.log("Enabling Button"); setReplayable(true);}, null, "+=2")
    })

    const replay = (e) => {
        e.preventDefault();
        console.log("REPLAYING");
        console.log("playing?: ", playing);
        if(playing === true) {console.log("playing", playing); return;}
        tl.restart();
    };

    return (
        <div className="container" ref={container}>
            <div className="hidden absolute top-0 right-0" ref={nowplaying}>Now Playing</div>
            {test &&
                <div ref={hello} className=''>Hello</div>
            }
            <div class="one h-screen flex flex-col" style={{ backgroundImage: `url(${img1})`, backgroundRepeat:"no-repeat", backgroundSize:"contain", backgroundPosition: "center"}}>
              <h1 class="one">
                <span className="text-black" data-node-name="greeting">Hi, </span>
                <span className="text-black" data-node-name="name">Aishwarya</span>
              </h1>
              <p class="two" data-node-name="greetingText"></p>
            </div>
            <div class="text-1 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">for a year</p>
              <img src={img17} width={500}></img>
            </div>
            <div class="text-2 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">you have been by my side</p>
              <img src={img15} width={500}></img>
            </div>
            <div class="text-3 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">through thick</p>
              <img src={img9} width={500}></img>
            </div>
            <div class="text-4 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">and thin</p>
              <img src={img4} width={500}></img>
            </div>
            <div class="text-5 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">from fancy</p>
              <img src={img20} width={500}></img>
            </div>
            <div class="text-6 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">to not so fancy</p>
              <img src={img19} width={500}></img>
            </div>
            <div class="text-7 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">i had the happiest year</p>
              <img src={img21} width={500}></img>
            </div>
            <div class="text-8 h-full flex flex-col items-center justify-center">
              <p class="text-[50px]">because of you</p>
              <img src={img14} width={500}></img>
            </div>
            <div class="three">
              <p class="text1">It has been a while</p>
              <p class="text2">i've been wanting to tell you</p>
              <p class="text3">about something</p>
              <p class="text4">that has been in my head</p>
            </div>
            <div class="four">
              <div class="text-box">
                <p class="hbd-chatbox" data-node-name="textInChatBox">What is it?</p>
                <p class="fake-btn" data-node-name="sendButtonLabel">Send</p>
              </div>
              <p class="askin" style={{fontSize: 'small'}} data-node-name="sendButtonLabel">you might ask</p>
            </div>
            <div class="five">
              <p class="idea-1" data-node-name="text2">My heart and my mind</p>
              <p class="idea-2" data-node-name="text3">are convinced that</p>
              <p class="idea-3">
                <span class="subidea-1" data-node-name="text4">You are</span>
                <span class="subidea-2" data-node-name="text4"> the </span>
                <strong class="subidea-3" data-node-name="text4Adjective">smartest</strong>.
                <img class="smart" src={img28}></img>
              </p>
              <p class="idea-4">
                <span class="subidea-4" data-node-name="text4">and</span>
                <span class="subidea-5" data-node-name="text4"> the </span>
                <strong class="subidea-6 p-1" data-node-name="text4Adjective">strongest</strong>
                <img class="stronk" src={img7}></img>
              </p>
              <p class="idea-45">
                <span>you're special</span>
                <span class="an">and</span>
                <img class="spesal" src={img24}></img>
              </p>
              <p class="idea-5">
                <span data-node-name="text5Content">the prettiest girl in the world</span>
                <span class="smiley" data-node-name="smiley">:)</span>
                <img class="pwetty" src={img12}></img>
              </p>
              <p class="idea-6">
                <span class="bigTextPart1">I</span>
                <span class="bigTextPart2">LOVE</span>
                <span class="bigTextPart3">YOU</span>
              </p>
            </div>
            <div class="six">
              <img src="./images/img16.jpg" alt="" class="aishb-dp" data-node-name="imagePath"></img>
              <img src="./images/hat.svg" alt="" class="hat"></img>
              <div class="wish">
                <h3 class="wishHBD text-[5rem]" data-node-name="wishHeading">and with all my heart</h3>
                <h4 class="wishHBD text-[5rem]">I wish you a very happy birthday</h4>
                <h5 class="wishNext" data-node-name="wishText">You mean the world to me. Hope to see you everyday for ever</h5>
                <p class='linx text-xs'>psst, my fav picture is.... prettiestinthe.world/favpicture</p>
                <p class='linx text-xs'>psst, my fav gallery is.... prettiestinthe.world/gallery</p>
              </div>
            </div>
            <div class="seven">
              <div class="baloons">
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon2.svg" alt=""></img>
                <img src="./images/ballon1.svg" alt=""></img>
                <img src="./images/ballon3.svg" alt=""></img>
              </div>
            </div>
            <div class="eight">
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
              <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" />
              </svg>
            </div>
            <button onClick={(e) => replay(e)} id="replay-button" className='hidden bg-stone-300 text-black border rounded-lg px-2 py-1 mt-2'>Play Again</button>
        </div>
    )
}

export default Interactive